import React from 'react';
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoutes from './routes/AdminRoutes';
import GuestRoutes from './routes/GuestRoutes';
import LoginPage from './pages/LoginPage';
function App() {
  return (
      <Router>
            <Routes>

              {/* Route to the admin routes */}
              <Route path="/admin/*" element={<AdminRoutes />} />
              {/* Route to the guest routes */}
              <Route path="/" element={<GuestRoutes />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<GuestRoutes />} />
            </Routes>
      </Router>
  );
}

export default App;
