import React from "react";
import logo from "../../assets/logo.png";
import user from "../../assets/icons/icon_user.png";

const HeaderAdmin = () =>{
    return (
        <header className="bg-[#b57849] shadow-md fixed z-[1000] w-full top-0">
            <div className="w-full px-4">
                <div className="grid grid-cols-2">
                    <div className="align-items-start d-flex justify-content-start margin-header">
                        <div className="h-20 p-2">
                            <img src={logo} alt="logo" className="h-full w-auto" />
                        </div>
                    </div>
                    <div className="flex justify-end items-center">

                        <p className="mb-0">Admin</p>
                        <img src={user} alt="user" className="h-8 ml-2 mr-2" />

                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderAdmin;