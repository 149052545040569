import React from 'react';
import icon_success from '../../assets/icons/icon_success.png';

const DialogWaiting = ({title, message,  onClose, isSuccess }) => {

    return (
        <div  className='fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-[1100]'>
            <div className='bg-[#ffffff] rounded-lg w-[500px] text-center'>
                <h2 className='w-full p-3 bg-[bisque] rounded-lg'>{title}</h2>
                {isSuccess ? (
                    <div className='grid-cols-1'>
                        <div className='flex justify-center pb-2'>
                            <img src={icon_success} alt='icon_success' className='w-10 h-10' />
                        </div>
                        <div>
                            <p>{message}</p>
                        </div>

                    </div>
                ) : (
                    <div className="loader"></div>
                )}
                <div className='p-2'>
                    <button className='bg-[#e7a250] ml-2 mr-2 pt-2 pb-2 pl-5 pr-5 cursor-pointer rounded text-[#ffffff]' onClick={onClose}> OK </button>
                </div>
            </div>
        </div>
    );
};


export default DialogWaiting;
