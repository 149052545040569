import React, {useState, useRef, useEffect} from 'react';
import Filter from '../../components/Guest/Filter';
import ListProduct from '../../components/Guest/ListProduct';
import axios from 'axios';

const Collection = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(true);

  const toggleSidebar = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const transformRef = useRef(null);
  const ref_slide_bar = useRef(null);
  const refWidth = useRef(250); // Chiều rộng cố định của FunctionAdmin

  useEffect(() => {
      if(ref_slide_bar.current){
          const { width } = ref_slide_bar.current.getBoundingClientRect();
          refWidth.current = width; // Update the ref
      }

      if (transformRef.current) {
          const transform = transformRef.current;
          if (window.innerWidth <= 768)
            return;
          const widthAdjustment = isOpenFilter ? refWidth.current : 0; // Điều chỉnh chiều rộng dựa trên isOpenFilterAdmin
          transform.style.width = `calc(100% - ${widthAdjustment}px)`;
          const xTransform = isOpenFilter ? refWidth.current : 0;
          transform.style.transform = `translateX(${xTransform}px)`
      }
  }, [isOpenFilter]);
  
  const [listItems, setListItems] = useState([]);
  const [page, setPage] = useState(1); // Quản lý trạng thái của trang hiện tại
  const [amountPage, setAmountPage] = useState(1);
  const [color, setColor ] = useState("");
  const [ size, setSize ] = useState([]);
  const [cate, setCate] = useState([]);

  const updatePage = (newPage) =>{
    setPage(newPage);
    setIsLoading(true);
    setLoadingImages({});
  };

  const updateColor = (color) =>{
    setColor(color);
    setIsLoading(true);
    setLoadingImages({});
  }

  const updateSize = (size) =>{
    setSize(size);
    setIsLoading(true);
    setLoadingImages({});
  }

  const updateCate = (category)=>{
    setCate(category);
    setIsLoading(true);
    setLoadingImages({});
  }

  useEffect(() => {
    const fetchItems = async () => {
      const sizeString = size.join(",");
      const cateString = cate.join(",");
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/getListItems', {
                params: { page, color, size : sizeString, category: cateString } // Truyền tham số page vào yêu cầu
            });

            // Tính tổng số trang
            const totalPages = Math.ceil(response.data.total / response.data.limit);
            // Cập nhật số trang và dữ liệu bộ sưu tập
            setAmountPage(totalPages);
            setListItems(response.data.items);
            setIsLoading(false);
        } catch (error) {
            console.error('Lỗi khi lấy bộ sưu tập:', error);
            // Xử lý lỗi, chẳng hạn như hiển thị thông báo lỗi
        }
    };

    fetchItems(); // Lấy dữ liệu bộ sưu tập khi component được tải
  }, [page, color, size, cate]);

  const [isLoading, setIsLoading] = useState(true);

  const updateLoading = (bool)=>{
    setIsLoading(bool);
  }

  const [loadingImages, setLoadingImages] = useState({});

  const handleImageLoad = (index) => {
      setLoadingImages((prev) => ({
          ...prev,
          [index]: false, // Đặt trạng thái ảnh đã tải
      }));
  };

  const handleImageError = (index) => {
      console.error(`Hình ảnh ở vị trí ${index} không thể tải.`);
  };

  return (
    <div className='d-flex'>
      <Filter ref={ref_slide_bar} listItems={listItems} updateColor={updateColor} color={color} updateSize={updateSize} size={size}
        updateCate={updateCate} cate={cate}  isOpenFilter={isOpenFilter} toggleSidebar={toggleSidebar}/>
      <ListProduct ref={transformRef} listItems={listItems} isOpenFilter={isOpenFilter} page={page} amountPage={amountPage} 
        updatePage={updatePage} isLoading={isLoading} updateLoading={updateLoading} loadingImages={loadingImages} 
        handleImageLoad={handleImageLoad} handleImageError={handleImageError} />
      
    </div>
  );
};

export default Collection;