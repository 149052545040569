import React from "react";

const Background = () => {
  return (
    <div className="row mt-4 mx-0">
      <div className="grid place-content-center">
        <img src="https://res.cloudinary.com/dknklb6z9/image/upload/v1731988843/hqqrzxoyd5m8flgcwrqg.jpg" 
        alt="background" loading="lazy"  className="img-fluid"/>
      </div>
    </div>
  );
};

export default Background;
