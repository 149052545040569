// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white pt-5 pb-5 mt-10 z-[1102] pl-0 pr-0">
      <div className="container">
        <div className="row">
          <div className="col-3">
            <h5 className='md:mb-5 mb-2'>About Us</h5>
            <p className='m-0 md:text-sm text-xs'>We are a company that provides excellent services and products.</p>
          </div>
          <div className="col-3 md:text-sm text-xs">
            <h5>Quick Links</h5>
            <ul className='list-none p-0'>
              <li className='mt-3'><a className='no-underline text-white hover:underline' href="/">Home</a></li>
              <li className='mt-3'><a className='no-underline text-white hover:underline' href="/about">About</a></li>
              <li className='mt-3'><a className='no-underline text-white hover:underline' href="/contact">Contact</a></li>
            </ul>
          </div>
          <div className="col-3 md:text-sm text-xs">
            <h5>THDT</h5>
            <ul className='list-none p-0'>
              <li className='mt-3'><a className='no-underline text-white hover:underline' href="https://s.shopee.vn/AKJvXH9uyy">Shoppee</a></li>
              <li className='mt-3'><a className='no-underline text-white hover:underline' href="https://redirect.zalo.me/v3/verifyv2/pc?token=OMxtnTz_LmzW1F34tHPMO6KAxQhG6LPWQGtjh3a&continue=https%3A%2F%2Fwww.facebook.com%2Fshare%2F18RqZzWmro%2F%3Fmibextid%3DLQQJ4d">Facebook</a></li>
              <li className='mt-3'><a className='no-underline text-white hover:underline' href="https://www.instagram.com/asl_gallery_/profilecard/?igsh=bXhvdW9pMzM2bjFr">Instagram</a></li>
            </ul>
          </div>
          <div className="col-3 md:text-sm text-xs">
            <h5>Contact Us</h5>
            <p>Số điện thoại: 093 845 2588 - 096 122 2076</p>
            <p>Địa chỉ: Lầu 8 - 90 Trần Đình Xu - Phường Cô Giang - Quận 1 - Thành phố Hồ Chí Minh</p>
          </div>
        </div>
        <div className="mt-3 text-xs border-t border-gray-600 pt-3">
          <p className='m-0 text-sm'>&copy; 2024 Your Company Name. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
