import React, { useState } from 'react';
import search from '../../assets/icons/icon_search.png';
const Search = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    if (onSearch) {
      onSearch(query);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="relative flex items-center">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
          className="font-tiktok-regular text-black rounded-3xl border border-[#ccc] lg:pl-4 lg:pt-2 lg:pb-2 
          xl:w-80 lg:w-72 md:w-56  w-56 lg:text-base pl-2 pt-1 pb-1 text-sm"
        />
        <img
          src={search}
          onClick={handleSearch}
          alt="Search"
          className="absolute right-2 cursor-pointer w-auto lg:h-6 h-3 lg:hover:h-7 hover:h-4"
        />
      </div>
    </div>
  );
};

export default Search;
