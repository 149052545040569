import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const TopCollection = ({ typeItem }) => {

  const [listItem, setListItem] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    const fetchData = async () =>{
      const response = await axios.get(process.env.REACT_APP_HOST + '/item_page_main?type=' + typeItem);
      setListItem(response.data.itemPageMain);
      setIsLoading(false);
    }
    fetchData();

  }, [typeItem])

  const hanleClickItem = (id)=>{
    navigate('/product/'+ id);
  }

  const [loadingImages, setLoadingImages] = useState({});

  const handleImageLoad = (index) => {
      setLoadingImages((prev) => ({
          ...prev,
          [index]: false, // Đặt trạng thái ảnh đã tải
      }));
  };

  const handleImageError = (index) => {
      console.error(`Hình ảnh ở vị trí ${index} không thể tải.`);
  };

const convertMoney = (money)=>{
  const formatter = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
  });
  return formatter.format(money);
}
  return (
    <div className="mt-5">
      <div className="container">
        <div className="w-100 text-center">
          {typeItem === 1 && <h2>Sản phẩm mới</h2>}
          {typeItem === 2 && <h2>Sản phẩm khuyến mãi</h2>}
        </div>
        {isLoading ? 
              <div className="flex items-center justify-center h-40">
                <div className="w-10 h-10 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"/>
              </div>
              :

              <div className="row justify-content-center d-flex">
              {Array.isArray(listItem) && listItem.map((item, index) => (
                  <div className="col-md-3 col-sm-6 col-6" key={index}>
                    <div className="rounded-xl shadow-item cursor-pointer" onClick={(e) =>{hanleClickItem(item.itemId)}}>
                      <div className="relative overflow-hidden">
                        {/* Skeleton */}
                        <div className={`inset-0 w-full bg-gray-200 animate-pulse flex h-[306px] justify-center items-center
                            ${loadingImages[index] === false ? "hidden" : ""}`}>
                          <div className="w-10 h-10 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"/>
                        </div>
                        {/* Image */}
                        <img src={process.env.REACT_APP_UPLOAD + item.item.fontImage} alt="item"
                          className={`rounded-xl w-full transition-all duration-300 ${loadingImages[index] === false ? "opacity-100" : "opacity-0"}`}
                          loading="lazy"
                          onLoad={() => handleImageLoad(index)}
                          onError={() => handleImageError(index)}
                        />
                      </div>
                    </div>
                    <p className="text-left font-bold sm:text-xs md:text-sm lg:text-base xl:text-lg xl:h-20">{item.item.name} </p>
                    <p className="text-left text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl">{convertMoney(item.item.price)}</p>
                  </div>
              ))}
    
            </div>
        }
        <div className="flex justify-center mt-2">
          <a href="/collection">
            <button className="bg-black pt-2 pb-2 pl-4 pr-4 xl:text-base text-white rounded-xl hover:bg-gray-500">Seemore</button>
          </a>
        </div>

      </div>
    </div>
  );
};

export default TopCollection;
