import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Guest/Header';
import Footer from '../components/Guest/Footer';
import Home from '../pages/Guest/Home';
import Collection from '../pages/Guest/Collection';
import Product from '../pages/Guest/Product';
import Cart from '../pages/Guest/Cart';
import Payment from '../pages/Guest/Payment';
import DialogGuestService from '../service/DialogGuestService';
import DialogWaitingService from '../service/DialogWaitingService';

const GuestRoutes = () => {
  return (
    <>
    <div className="page-wrapper">
      <DialogGuestService />
      <DialogWaitingService />
      <Header />
        <main>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
        </main>
      <Footer />
      </div>
  </>
  );
};

export default GuestRoutes;
