import React, {useState, useEffect} from "react";
import iconAddPhoto from '../../assets/icons/icon_add_photo.jpg';
import iconClose from '../../assets/icons/icon_close.png';
import { closeDialogSearchItem, openDialogSearchItem } from "../../service/DialogSearchItemService";
import { openDialogWaitingAdmin, closeDialogWaitingAdmin } from "../../service/DialogWatingAdminService";
import axios from "axios";

const HighlightItem = ()=>{
    const TYPE_NOI_BAT = 1;
    const TYPE_GIAM_GIA = 2;

    const[item_noi_bat, setItem_noi_bat] = useState([]);
    const[item_giam_gia, setItem_giam_gia] = useState([]);
    const[typeHighlight, setTypeHighlight] = useState(0);

    const handleClickAddItem = (typeHighlight) =>{
        setTypeHighlight(typeHighlight);
        openDialogSearchItem(handleClickOK, typeHighlight);
    }

    useEffect( ()=>{
        const fetchData = async () =>{
            const responseNoiBat = await axios.get(process.env.REACT_APP_HOST + '/item_page_main?type=' + TYPE_NOI_BAT);
            setItem_noi_bat(responseNoiBat.data.itemPageMain);

            const responseGiamGia = await axios.get(process.env.REACT_APP_HOST + '/item_page_main?type=' + TYPE_GIAM_GIA);
            setItem_giam_gia(responseGiamGia.data.itemPageMain);
        }
        fetchData();
    }, [])

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    const handleRemovetItem = async (idItem)=>{
        try {
            openDialogWaitingAdmin();

            const response = await axios.delete(process.env.REACT_APP_HOST + '/item_page_main/' + idItem);
            console.log(response.data);
            if(response.data.message === "Delete success")
            {
                closeDialogWaitingAdmin();
                if(typeHighlight === TYPE_NOI_BAT)
                    removeItemNoiBat(idItem);
                else if(typeHighlight === TYPE_GIAM_GIA)
                    removeItemGiamGia(idItem);
            }
        } catch (error) {
            console.error("Error fetching collections:", error);
        }
    }

    const removeItemNoiBat = (itemId) => {
        setItem_noi_bat(prevItems => prevItems.filter(item => item.id !== itemId));
    };

    const removeItemGiamGia = (itemId) => {
        setItem_giam_gia(prevItems => prevItems.filter(item => item.id !== itemId));
    };

    const addItemNoiBat = (newItem) => {
        setItem_noi_bat(prevItems => [...prevItems, newItem]);
    };

    const addItemGiamGia = (newItem) => {
        setItem_giam_gia(prevItems => [...prevItems, newItem]);
    };

    const handleClickOK = async (idItem, typeHighlight1) =>{
        openDialogWaitingAdmin();
        try {
            const response = await axios.post(process.env.REACT_APP_HOST + '/item_page_main', {
                type : typeHighlight1,
                itemId: idItem
            });
            closeDialogWaitingAdmin();
            if(response.data.message === "Create success!!!"){
                closeDialogSearchItem();
                if(typeHighlight1 === TYPE_NOI_BAT)
                    addItemNoiBat(response.data.item);
                else if(typeHighlight1 === TYPE_GIAM_GIA)
                    addItemGiamGia(response.data.item);
                
            }
        } catch (error) 
        {
            console.error("Error fetching items:", error);
        }
    }

    return (
        <>
         <div className="px-2">
            <div className="row">

                <div className="mb-3 mx-0 col-xxl-10 row">
                    <p className="bg-[#f69954] py-[2px] rounded pl-2">Sản phẩm nổi bật</p>
                    <div className="hight-light-item">
                        <div className="row justify-content-start d-flex">
                            {Array.isArray(item_noi_bat) && item_noi_bat.map((item_noi_bat, index) => (
                                <div className="col-md-3 col-sm-3 col-xxl-3 mt-2" key={index}>
                                    <div className="position-relative d-inline-block  group hover">
                                        <img src={process.env.REACT_APP_UPLOAD+ item_noi_bat.item.fontImage} alt="item" 
                                        className="w-60 h-60 rounded-xl group-hover:border-0.5 group-hover:border-black" loading="lazy"/>
                                        <img src={iconClose} alt="itemClose" className="w-5 h-5 absolute top-[-10px] right-[-10px] cursor-pointer
                                        hidden group-hover:block" loading="lazy"
                                        onClick={(e) => handleRemovetItem(item_noi_bat.id)} />
                                    </div>
                                    <p className="text-left font-bold sm:text-xs md:text-sm lg:text-base xl:text-lg xl:h-20 line-clamp-3">{item_noi_bat.item.name} </p>
                                    <p className="text-left m-0 text-base">{convertMoney(item_noi_bat.item.price)}</p>
                                </div>
                            ))}
                            <div className="col-md-3 col-sm-3 col-xxl-3">
                                <div className="group-icon" onClick={(e) => handleClickAddItem(TYPE_NOI_BAT)}>
                                    <img src={iconAddPhoto} alt="item" className="w-60 h-60 rounded-xl cursor-pointer" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3 mx-0 col-xxl-10 row">
                    <p className="bg-[#f69954] py-[2px] rounded pl-2">Sản phẩm giảm giá</p>

                    <div className="hight-light-item">
                        <div className="row justify-content-start d-flex">
                            {Array.isArray(item_giam_gia) && item_giam_gia.map((item_giam_gia, index) => (
                                <div className="col-md-3 col-sm-3 col-xxl-3 mt-2" key={index}>
                                    <div className="position-relative d-inline-block group hover">
                                        <img src={process.env.REACT_APP_UPLOAD+ item_giam_gia.item.fontImage} alt="item" 
                                        className="w-60 h-60 rounded-xl group-hover:border-0.5 group-hover:border-black" loading="lazy"/>
                                        <img src={iconClose} alt="itemClose" className="w-5 h-5 absolute top-[-10px] right-[-10px] cursor-pointer
                                        hidden group-hover:block " loading="lazy"
                                        onClick={(e) => handleRemovetItem(item_giam_gia.id)} />
                                    </div>
                                    <p className="text-left font-bold sm:text-xs md:text-sm lg:text-base xl:text-lg xl:h-20 line-clamp-3">{item_giam_gia.item.name} </p>
                                    <p className="text-left m-0 text-base">{convertMoney(item_giam_gia.item.price)}</p>
                                </div>
                            ))}
                            <div className="col-md-3 col-sm-3 col-xxl-3">
                                <div className="group-icon" onClick={(e) => handleClickAddItem(TYPE_GIAM_GIA)}>
                                    <img src={iconAddPhoto} alt="item" className="w-60 h-60 rounded-xl cursor-pointer" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
         </div>
        </>
    )
}


export default HighlightItem;
