import React, {useEffect, useState} from "react";
import './DialogSearchItem.scss';
import {saveLocalStorage, loadLocalStorage} from "../../../utils/LocalStorage";
import axios from "axios";
import { closeDialogWaitingAdmin, openDialogWaitingAdmin } from "../../../service/DialogWatingAdminService";

const DialogSearchItem = ({ onClose, onSuccess, typeHighlight}) => {

    const [collections, setCollections] = useState([]);
    const [listItem, setListItem] = useState([]);
    const [idCollection, setIdCollection] = useState(-1);
    const [isShowItem, setIsShowItem] = useState(true);
    const [idItem, setIdItem] = useState();

    useEffect(()=>{
        const storedCollections = loadLocalStorage("collections");
        if(!storedCollections){
            saveLocalStorage("collections", '/getSelectCollections', setCollections);
        }else{
            setCollections(storedCollections);
        }
    }, []) 

    const onChangeCollection = async (event)=>{
        if(idCollection === event.target.value)
            return;
        await setIdCollection(event.target.value);
        openDialogWaitingAdmin();
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/itemByCollectionId/' + event.target.value);
            setListItem(response.data);
            closeDialogWaitingAdmin();
        } catch (error) 
        {
            console.error("Error fetching items:", error);
        }
    }

    const [query, setQuery] = useState('');




    // Lọc options dựa trên giá trị tìm kiếm
    const filteredOptions = listItem.filter(option => 
        option.name.toLowerCase().includes(query.toLowerCase())
    );

    const handleSelect = (option) => {
        setQuery(option.name); // Hiển thị tên option trong input
        setIsShowItem(false);
        setIdItem(option.id);
    };

    const handleClickInput = ()=>{
        setIsShowItem(true);
    }

    const handleOKe = ()=>{
        console.log("dialog:  " + typeHighlight);
        onSuccess(idItem, typeHighlight);
    } 



    return (
        <div  className='dialog-search-item'>
            <div className='dialog'>
                <h2>Chọn sản phẩm</h2>
                <div className="container">
                    
                    <div className="mb-3 col-xxl-12">
                        <label htmlFor="name-collection" className="form-label">Bộ sưu tập</label>
                        <select id="dropdown" className="form-control"  onChange={onChangeCollection} >
                            <option value="">-- Chọn bộ sưu tập --</option>

                            {collections.length > 0 && collections.map((collection) => (
                                <option key={collection.id} value={collection.id}>
                                    {collection.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3 col-xxl-12">
                        <label htmlFor="name-collection" className="form-label">Sản phẩm</label>
                        
                        {/* Container for input and dropdown */}
                        <div className="input-container">
                            <input  type="text"  className="form-control"  value={query} 
                            onChange={(e) => setQuery(e.target.value)}  
                            onClick={handleClickInput}
                            readOnly={listItem.length === 0} />
                            
                            {/* Dropdown list */}
                            {
                                isShowItem && filteredOptions.length > 0 && (
                                    <>
                                    {/* Your content here, e.g., .option-item div */}
                                    <div className="option-item">
                                        <ul>
                                        {filteredOptions.map((option) => (
                                            <li 
                                            key={option.id} 
                                            onClick={() => handleSelect(option)}
                                            style={{ cursor: 'pointer', padding: '5px' }}
                                            >
                                            {option.name}
                                            </li>
                                        ))}
                                        <li>....</li>
                                        </ul>
                                    </div>
                                    </>
                                )
                                }
                        </div>
                    </div>
                </div>
                <div className='button-dialog'>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>No</button>
                    <button type="button" className="btn btn-success" onClick={(e) =>{handleOKe()} } >OK</button>
                </div>
            </div>
        </div>
    );
};


export default DialogSearchItem;