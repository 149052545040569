import React from "react";
import ItemManage from "../../components/Admin/ItemManage";

const ItemAdmin = () =>{
    return (
        <>
            <ItemManage />
        </>
    );
}

export default ItemAdmin;