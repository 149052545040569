import React, { forwardRef } from 'react';
import Pagination from './Pagination';
import { useNavigate } from 'react-router-dom';

const ListProduct = forwardRef(({ isOpenFilter , listItems, page, amountPage, updatePage, isLoading,
    loadingImages,handleImageLoad, handleImageError
}, ref) =>{


    const navigate = useNavigate();

    const onPageChange = (page)=>{
        updatePage(page);
    }

    const hanleClickItem = (id)=>{
        navigate('/product/'+ id);
    }

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }



    return (
        <>
            <div ref={ref} className='transition-all duration-300 ease-in-out ml-0'>
                <div className= {`${isOpenFilter ? 'container-fluid' : 'container'}`}>
                    <div className="row justify-center flex items-center mt-3 min-h-screen">
                    {
                        isLoading ?

                        <div className="flex items-center justify-center h-40">
                            <div className="w-10 h-10 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
                        </div>
                        :
                        <>
                    {listItems && listItems.map((item, index) => (
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 padding-item" key={index}>
                            <div className="relative overflow-hidden">
                                {/* Skeleton */}
                                <div className={`inset-0 w-full bg-gray-200 animate-pulse flex h-[306px] justify-center items-center
                                    ${loadingImages[index] === false ? "hidden" : ""}`}>
                                    <div className="w-10 h-10 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"/>
                                </div>
                                {/* Image */}

                                <div className="shadow-item w-full h-90 rounded-xl relative"  onClick={()=> hanleClickItem(item.id)}>
                                <img src={process.env.REACT_APP_UPLOAD +  item.fontImage} alt="item" 
                                    className={`w-full h-full rounded-xl object-cover transition 
                                    duration-1000 ease-in-out transform hover:scale-110 ${loadingImages[index] === false ? "opacity-100" : "opacity-0"}`}
                                    loading='lazy'
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageError(index)}/>
                            </div>
                            </div>


                            <p className="pt-2 line-clamp-2 m-0">{item.name}</p>
                            <p className="font-tiktok-medium text-xs text-gray-500 m-0">{item.collectionName}</p>
                            <p className="text-2xl">{convertMoney(item.price)}</p>
                        </div>
                    ))}
                    </>

                    }


                    </div>
                </div>
                <Pagination currentPage={page} amountPage={amountPage} onPageChange={onPageChange} />
            </div>
        </>
    );
});

export default ListProduct;