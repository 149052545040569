import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DialogWaiting from '../components/Dialog/DialogWaiting';

// Biến lưu trữ trạng thái của Dialog và các hàm liên quan
let dialogData = {
    title: "Xin chờ",
    message: "",
    isOpen: false,
    isSuccess: false,
    onConfirm: () => {},
  };
  
let setDialogData;

// Hàm để hiển thị Dialog
export const openDialogWaiting = (onConfirm) => {
    setDialogData({
      isOpen: true,
      onConfirm,
    });
};

// Hàm để đóng Dialog
export const closeDialog = () => {
    setDialogData({
      ...dialogData,
      isOpen: false,
    });
};

export const onSuccess = () => {
  setDialogData(prevData => ({
    ...prevData,        // Giữ nguyên các thuộc tính hiện tại
    isSuccess: true,    // Cập nhật thuộc tính isSuccess
    title: "Đặt hàng thành công",
    message: "Bạn đã đặt hàng thành công, nhân viên sẽ liên hệ bạn sớm nhất có thể"
}));
};

const DialogWaitingServie = () =>{
    const [localData, setLocalData] = useState(dialogData);
    setDialogData = setLocalData; // Gán setDialogData để cập nhật trạng thái
    if (!localData.isOpen) return null; // Không hiển thị khi isOpen = false

    return ReactDOM.createPortal(
        <DialogWaiting 
          onClose={localData.onConfirm != null ? localData.onConfirm : closeDialog} 
          isSuccess={localData.isSuccess} 
          title={localData.title}  
          message={localData.message}
          />,
        document.body
    )
}

export default DialogWaitingServie;