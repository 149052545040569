import React, {useState} from "react";
import axios from "axios";
import { openDialogWaiting, onSuccess } from "../../service/DialogWaitingService";
import { clearCart } from "../../redux/cartSlice";
import { useSelector, useDispatch } from 'react-redux';
const DetailsPayment = ()=>{

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [noti, setNoti] = useState("");
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart.items)
    const total = useSelector((state) => state.cart.totalPrice);

    const handleInputNumric = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Only allow digits
            setPhone(value);
        }
    };

    const [validEmail, setValidEmail] = useState('');
    const [validName, setValidName] = useState('');
    const [validPhone, setValidPhone] = useState('');
    const [validAddress, setValidAddress] = useState('');

    const resetValid = ()=>{
        setValidName("");
        setValidEmail("");
        setValidPhone("");
        setValidAddress("");
    }

    const handleClickOrder = async ()=>{
        resetValid();
        let isValid = false;
        if(email ===""){
            setValidEmail("Bạn chưa nhập email");
            isValid = true;
        }
        if(name === ""){
            setValidName("Bạn chưa nhập tên");
            isValid = true;
        }
        if(phone === ""){
            setValidPhone("Bạn chưa nhập số điện thoại");
            isValid = true;
        }
        if(address === ""){
            setValidAddress("Bạn chưa nhập địa chỉ");
            isValid = true;
        }
        if(isValid)
            return;

        openDialogWaiting(null);
        try{
            const response = await axios.post(process.env.REACT_APP_HOST + '/receipts', {
                name: name,
                email : email,
                phone : phone,
                address: address,
                notification : noti,
                total: total,
                items : cart
            });
            if(response.data.message === 'Create success!!!')
            {
                onSuccess();
                dispatch(clearCart());
            }


        }catch(error){
            console.log(error);
        }

    }

    return(
        <div className="container details-payment">
            <div className="row">
                <div className="col-xxl-6 col-12">
                    <p className="text-3xl">Thông tin mua hàng</p>
                    <div className="p-2">
                        <input type="email" className="form-control font-tiktok-regular " placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                        <div className="mx-1 text-red-500 text-xs">{validEmail}</div>
                    </div>
                    <div className="p-2">
                        <input type="text" className="form-control font-tiktok-regular" placeholder="Tên" onChange={(e) => setName(e.target.value)}/>
                        <div className="mx-1 text-red-500 text-xs">{validName}</div>
                    </div>
                    <div className="p-2">
                        <input type="text" className="form-control font-tiktok-regular" placeholder="Số điện thoại" value={phone}  onChange={handleInputNumric}/>
                        <div className="mx-1 text-red-500 text-xs">{validPhone}</div>
                    </div>
                    <div className="p-2">
                        <input type="text" className="form-control font-tiktok-regular" placeholder="Địa chỉ" onChange={(e) => setAddress(e.target.value)}/>
                        <div className="mx-1 text-red-500 text-xs">{validAddress}</div>
                    </div>
                    <div className="form-group p-2">
                        <textarea className="form-control font-tiktok-regular" rows="3" placeholder="Ghi chú" onChange={(e) => setNoti(e.target.value)}></textarea>
                    </div>

                </div>
                <div className="col-xxl-6 col-12">
                    <div className="container-fluid">
                        <p className="text-3xl">Vận chuyển</p>
                        <div className="bg-blue-100 rounded-lg mt-3 mb-3 flex items-center justify-content-center text-center">
                            <p className="font-tiktok-regular text-xl mt-3 mb-3">Vui lòng nhập thông tin giao hàng</p>
                        </div>
                        <p className="text-3xl">Thanh toán</p>
                        <ul className="p-0 m-0 list-none">
                            <li>
                                <div className="p-1">
                                    <div className="form-check border-0.5 rounded-lg px-2 pt-4 pb-4">
                                        <input type="checkbox" className="form-check-input mx-3"/>
                                        <label className="form-check-label font-tiktok-regular" htmlFor="exampleCheck1">Thanh toán khi nhận hàng</label>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className="p-1">
                                    <div class="form-check form-method-payment px-2 pt-4 pb-4">
                                        <input type="checkbox" class="form-check-input mx-3"/>
                                        <label class="form-check-label text-payment-method" htmlFor="exampleCheck1">Momo</label>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="justify-content-center d-flex">
                <button className="items-center pt-3 pb-3 pr-12 pl-12 mt-3 xl:w-2/5 font-tiktok-medium
                 text-xl border-0.5 border-black rounded-3xl bg-[#000000] text-[#ffffff] shadow-md 
                 hover:bg-white hover:text-red-600 sm:w-4/5" onClick={handleClickOrder}>Đặt hàng</button>
            </div>
        </div>
    );
}

export default DetailsPayment;
