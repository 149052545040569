import React from "react";

const Carousel = () => {

  return (
    <div className="container-fluid p-1">
      <div id="carouselExampleIndicators" className="carousel slide z-0" data-bs-ride="carousel">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active grid place-items-center h-full">
        
        <img src="https://res.cloudinary.com/dknklb6z9/image/upload/v1731988236/zhiljhklljvin5kiisdi.jpg"
         className="img-fluid" alt="banner" loading="lazy"/>
        
        
      </div>
      <div className="carousel-item grid place-items-center">
        <img src="https://res.cloudinary.com/dknklb6z9/image/upload/v1731988844/txumcszrwvy6cijndg7f.jpg" className="img-fluid" alt="banner1" loading="lazy"/>
      </div>
      <div className="carousel-item grid place-items-center">
        <img src="https://res.cloudinary.com/dknklb6z9/image/upload/v1731988844/cydbknif3epur87eq13p.jpg" className="img-fluid" alt="banner2" loading="lazy"/>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>

    </div>

  );
};

export default Carousel;
