import React from 'react';
import Carousel from '../../components/Guest/Carousel';
import TopCollection from '../../components/Guest/TopCollection';
import Background from '../../components/Guest/Background';

const Home = () => {
  return (
    <div>
      <Carousel />
      <TopCollection typeItem={1} />
      <Background />
      <TopCollection typeItem={2}/>
    </div>
  );
};

export default Home;