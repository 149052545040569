import React, {useState, useRef, useEffect} from "react";
import iconAddPhoto from '../../assets/icons/icon_add_photo.jpg';
import iconClose from '../../assets/icons/icon_close.png';
import axios from "axios";
import { closeDialogWaitingAdmin, openDialogWaitingAdmin } from "../../service/DialogWatingAdminService";

const CreateItem = ({ item : initialItem, handleBack}) =>{

    const [nameItem, setNameItem] = useState('');
    const [price, setPrice] = useState(0);
    const [selectedImageFont, setSelectedImageFont] = useState(iconAddPhoto);
    const [fileFont, setFileFont] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [item, setItem] = useState(initialItem);

    const fileInputRefFont = useRef(null);

    const handleImageClickFont = () => {
        fileInputRefFont.current.click(); // Kích hoạt nhấp chuột cho input ẩn
    };

    const handleFileChangeFont = (event) => {
        setIsAlert(false);
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImageFont(reader.result); // Cập nhật hình ảnh được chọn
                setFileFont(file);
            };
            reader.readAsDataURL(file);
        }
    };

    function convertStringToArrayWithEmptyElement(inputString) {
        // Chia chuỗi thành mảng dựa trên dấu phân cách ','
        const array = inputString.split(',');  
        // Thêm phần tử rỗng vào cuối mảng
        array.push('');    
        return array;
    }

    useEffect(()=>{
        if(item){
            console.log(item);
            setNameItem(item.name);
            setPrice(item.price);
            setSelectedImageFont(process.env.REACT_APP_UPLOAD + item.fontImage);
            setSelectedImageBack(process.env.REACT_APP_UPLOAD + item.backImage);
            setSelectedCollection(item.Collection.id);
            if(item.description)
                setDescription(item.description);
            if(item.colors){
                const arrayColors = convertStringToArrayWithEmptyElement(item.colors);
                setInputFieldsColor(arrayColors);
            }
            if(item.sizes){
                const arraySizes = convertStringToArrayWithEmptyElement(item.sizes);
                setInputFieldsSize(arraySizes);
            }
            if(item.listImage){
                const arrayImage = item.listImage.split(',');
                // Prepend the base URL to each image path
                const updatedImageList = arrayImage.map(image => `${process.env.REACT_APP_UPLOAD}${image}`);
                setImageList(updatedImageList);
                for (const imageUrl of updatedImageList) {
                    addImageFromUrl(imageUrl);
                }
                // Update the state with the modified array
            }

        }
    }, [item])

    const addImageFromUrl = async (url) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', { type: blob.type });         
          setFileList((prevList) => [...prevList, file]);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
    };

    const [selectedImageBack, setSelectedImageBack] = useState(iconAddPhoto);
    const [fileBack, setFileBack] = useState(null);
    const fileInputRefBack = useRef(null);

    const handleImageClickBack = () => {
        fileInputRefBack.current.click(); // Kích hoạt nhấp chuột cho input ẩn
    };
    
    const handleFileChangeBack = (event) => {
        setIsAlert(false);
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImageBack(reader.result); // Cập nhật hình ảnh được chọn
                setFileBack(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(''); // State to store the selected option

    const handleSelectChange = (event) => {
        setIsAlert(false);
        setSelectedCollection(event.target.value);
    };

    // Function to fetch collections from the API
    const fetchCollections = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/getSelectCollections');
            setCollections(response.data); // Assuming the API returns an array of collections
        } catch (error) {
            console.error("Error fetching collections:", error);
        }
    };

    // Fetch collections when the component mounts
    useEffect(() => {
        fetchCollections();
    }, []);

    const [imageList, setImageList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isChangeFile, setIsChangeFile] = useState(false);
    const fileInputRefList = useRef(null);

    const handleImageClickList = () => {
        fileInputRefList.current.click(); // Kích hoạt nhấp chuột cho input ẩn
    };

    const handleAddImage = (e) => {
        setIsAlert(false);
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageList((prevList) => [...prevList, reader.result]);
                setFileList((prevList) => [...prevList, file]);
                setIsChangeFile(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = (indexToRemove) => {
        setImageList((prevList) => prevList.filter((_, index) => index !== indexToRemove));
        setFileList((prevList) => prevList.filter((_, index) => index !== indexToRemove));
        setIsChangeFile(true);
    };

    const [inputFieldsColor, setInputFieldsColor] = useState(['']);
    const inputRefsColor = useRef([]);
    const handleInputChangeColor = (index, value) => {
        const newInputFields = [...inputFieldsColor];
        newInputFields[index] = value;
        setInputFieldsColor(newInputFields);
    };

    const handleKeyDownColor = (index, event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent the default action of the Enter key (e.g., submitting a form)
          if (inputFieldsColor[index].trim() !== '' && index === inputFieldsColor.length - 1) {
            setInputFieldsColor([...inputFieldsColor, '']);
            setIsAlert(false);
          }

                  // Đặt focus vào ô input mới
        setTimeout(() => {
            if (inputRefsColor.current[index + 1]) {
              inputRefsColor.current[index + 1].focus();
            }
          }, 0);
        }
      };

    const handleRemoveInputColor = (index) => {
        setInputFieldsColor((prevFields) => prevFields.filter((_, i) => i !== index));
    };


    const [inputFieldsSize, setInputFieldsSize] = useState(['']);
    const inputRefsSize = useRef([]);

    const handleInputChangeSize = (index, value) => {

        const newInputFields = [...inputFieldsSize];
        newInputFields[index] = value;
        setInputFieldsSize(newInputFields);
    };

    const handleKeyDownSize = (index, event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent the default action of the Enter key (e.g., submitting a form)
          if (inputFieldsSize[index].trim() !== '' && index === inputFieldsSize.length - 1) {
            setInputFieldsSize([...inputFieldsSize, '']);
            setIsAlert(false);
          }

        // Đặt focus vào ô input mới
        setTimeout(() => {
            if (inputRefsSize.current[index + 1]) {
              inputRefsSize.current[index + 1].focus();
            }
          }, 0);
        }
      };

    const handleRemoveInputSize = (index) => {
        setInputFieldsSize((prevFields) => prevFields.filter((_, i) => i !== index));
    };

    function arrayToStringWithoutLastElement(arr) {
        // Loại bỏ phần tử cuối cùng và tạo chuỗi
        return arr.slice(0, -1).join(',');
    }

    const [validName, setValidName] = useState("");
    const [validPrice, setValidPrice] = useState("");
    const [validFontImage, setValidFontImage] = useState("");
    const [validBackImage, setValidBackImage] = useState("");
    const [validCollection, setValidCollection] = useState("");
    const [validColor, setValidColor] = useState("");
    const [validSize, setValidSize] = useState("");


    const resetValid = ()=>{
        setValidName("");
        setValidPrice("");
        setValidFontImage("");
        setValidBackImage("");
        setValidCollection("");
        setValidColor("");
        setValidSize("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        resetValid();
        let isValid = false;
        if(nameItem === "")
        {
            setValidName("Bạn chưa nhập tên sản phẩm");
            isValid = true;
        }
        if(price <= 0){
            setValidPrice("Bạn chưa nhập giá sản phẩm");
            isValid = true;
        }
        if(item === null && fileFont == null )
        {
            setValidFontImage("Bạn chưa nhập ảnh mặt trước");
            isValid = true;
        }
        if(item === null && fileBack == null)
        {
            setValidBackImage("Bạn chưa nhập ảnh mặt sau");
            isValid = true;
        }
        if(selectedCollection == null || selectedCollection === "")
        {
            setValidCollection("Bạn chưa chọn bộ sưu tập");
            isValid = true;
        }
        const colors = arrayToStringWithoutLastElement(inputFieldsColor);
        if(colors === "")
        {
            setValidColor("Bạn chưa nhập màu sắc sản phẩm");
            isValid = true;
        }
        const sizes = arrayToStringWithoutLastElement(inputFieldsSize);
        if(sizes === "")
        {
            setValidSize("Bạn chưa nhập size sản phẩm");
            isValid = true;
        }
        if(isValid) return;

        openDialogWaitingAdmin();
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('token', token);
        formData.append('name', nameItem);
        formData.append('price', price);
        formData.append('collectionId', selectedCollection);
        formData.append('description', description);
        formData.append('colors', colors);
        formData.append('sizes', sizes);
        if (selectedImageFont && fileFont) {
            formData.append('fontImage', fileFont);
        }
        if (selectedImageBack && fileBack) {
            formData.append('backImage', fileBack);
        }
        if(imageList && isChangeFile){
            fileList.forEach((file) => {
                formData.append('listImage', file); // 'listImage' is the key, which will be treated as an array
            });
        }

    
        try {
            let response;
            if(item === null){
                response = await axios.post(process.env.REACT_APP_HOST + '/items', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }else{
                console.log(item.id);
                response = await axios.put(process.env.REACT_APP_HOST + '/items/' + item.id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            setIsAlert(true);
            setMessage(response.data.message);
            setItem(response.data.item);
            closeDialogWaitingAdmin();
        } catch (error) {
            if (error.response) {
                if(error.response.status === 401){
                    //openDialog("Thông báo", "Phiên đăng nhập hết hạn" , logout);
                }
             
            } 
            console.log('Upload error:', error);
        }

    };

    const [description, setDescription] = useState('');

    const handleDescriptionChange = (event) => {
        setIsAlert(false);
        setDescription(event.target.value);
      };



    return (
        <div className="px-2">
        <h2>Tạo sản phẩm</h2>
        {/* Add your form or other content here */}
        <div className="row">
            <form onSubmit={handleSubmit}>
                <div className="mb-3 col-xxl-6">
                    <p className="bg-[#f69954] py-[2px] rounded pl-2">Thông tin cơ bản</p>
                </div>
                <div className="mb-3 col-xxl-6">
                    <label htmlFor="name-collection" className="form-label">Tên sản phẩm</label>
                    <input type="text" className="form-control" value={nameItem} onChange={(e) => {setNameItem(e.target.value); setIsAlert(false)}} />
                    <div  className="form-text text-red-500">{validName}</div>
                </div>

                <div className="mb-3 col-xxl-6">
                    <label htmlFor="name-collection" className="form-label">Giá sản phẩm</label>
                    <input type="text" className="form-control" value={price} onChange={(e) => {setPrice(e.target.value); setIsAlert(false)}} />
                    <div  className="form-text text-red-500">{validPrice}</div>
                </div>

                <div className="mb-3 col-xxl-6">
                    <label htmlFor="name-collection" className="form-label">Ảnh trước</label>
                    <div onClick={handleImageClickFont}>
                        <img src={selectedImageFont} className="img-thumbnail w-28 h-24 cursor-pointer hover:border-black hover:bottom-1" alt="iconAddPhoto" />
                    </div>
                    <input type="file" ref={fileInputRefFont} className="d-none" onChange={handleFileChangeFont} accept="image/*"  />
                    <div  className="form-text text-red-500">{validFontImage}</div>
                </div>

                <div className="mb-3 col-xxl-6">
                    <label htmlFor="name-collection" className="form-label">Ảnh sau</label>
                    <div  onClick={handleImageClickBack}>
                        <img src={selectedImageBack} className="img-thumbnail w-28 h-24 cursor-pointer hover:border-black hover:bottom-1" alt="iconAddPhoto" />
                    </div>
                    <input type="file" ref={fileInputRefBack} className="d-none" onChange={handleFileChangeBack} accept="image/*"  />
                    <div  className="form-text text-red-500">{validBackImage}</div>
                </div>

                <div className="mb-3 col-xxl-6">
                    <label htmlFor="name-collection" className="form-label">Bộ sưu tập</label>
                    <select id="dropdown" className="form-control" value={selectedCollection} onChange={handleSelectChange} >
                        <option value="">-- Chọn một tùy chọn --</option>
                        {collections.map((collection) => (
                            <option key={collection.id} value={collection.id}>
                                 {collection.name}
                            </option>
                        ))}
                    </select>
                    <div  className="form-text text-red-500">{validCollection}</div>
                </div>

                <div className="form-group col-xxl-6">
                    <label htmlFor="exampleFormControlTextarea1">Mô tả sản phẩm</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" value={description} onChange={handleDescriptionChange}></textarea>
                </div>

                <div className="mb-3 col-xxl-6 mt-3">
                    <label htmlFor="name-collection" className="form-label">Hình ảnh sản phẩm</label>
                    <div >
                        {imageList && imageList.map((img, index) => (
                            <React.Fragment key={index}>
                              <div className="relative inline-block group hover">
                                <img src={img} className="img-thumbnail m-1 w-28 h-24 hover:border-black hover:bottom-1" alt={`uploaded-img-${index}`} />
                                <img src={iconClose} className="w-5 h-5 absolute cursor-pointer hidden group-hover:block top-0 right-0" 
                                alt="icon_close" onClick={() => handleRemoveImage(index)} />
                              </div>
                            </React.Fragment>

                        ))}
                        <img src={iconAddPhoto} className="img-thumbnail w-28 h-24" alt="iconAddPhoto" onClick={handleImageClickList}/>
                    </div>

                    <input ref={fileInputRefList} type="file"  className="d-none"  accept="image/*" onChange={handleAddImage} />
                </div>
                
                <div className="mb-3 col-xxl-6">
                    <p className="bg-[#f69954] py-[2px] rounded pl-2">Thông tin bán hàng</p>
                </div>
                    
                <div className="mb-3 col-xxl-6">
                    <label className="form-label m-0">Màu sắc</label>
                    <div  className="form-text text-red-500">{validColor}</div>
                    <div className="row">
                        {inputFieldsColor.map((input, index) => (
                            <div className="col-xxl-6 my-1 group hover items-center flex justify-end" key={index}>
                                <input type="text" className="form-control" value={input} 
                                onChange={(e) => handleInputChangeColor(index, e.target.value)}  onKeyDown={(e) => handleKeyDownColor(index, e)} 
                                ref={(el) => (inputRefsColor.current[index] = el)} />
                                {index < inputFieldsColor.length - 1 && (
                                <img src={iconClose} className="w-5 h-5 absolute mr-2 cursor-pointer hidden group-hover:block" alt="icon_close"   onClick={() => handleRemoveInputColor(index)}/>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-3 col-xxl-6">
                    <label className="form-label m-0">Size</label>
                    <div  className="form-text text-red-500">{validSize}</div>
                    <div className="row">
                        {inputFieldsSize.map((input, index) => (
                            <div className="col-xxl-6 my-1 group hover items-center flex justify-end" key={index}>
                                <input type="text" className="form-control" value={input} 
                                onChange={(e) => handleInputChangeSize(index, e.target.value)}  onKeyDown={(e) => handleKeyDownSize(index, e)} 
                                ref={(el) => (inputRefsSize.current[index] = el)} />
                                {index < inputFieldsSize.length - 1 && (
                                <img src={iconClose} className="w-5 h-5 absolute mr-2 cursor-pointer hidden group-hover:block" alt="icon_close"   onClick={() => handleRemoveInputSize(index)}/>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`alert alert-success col-xxl-6 ${isAlert ? '' : 'd-none'}`} role="alert">
                    {message}
                </div>
                <button className="btn btn-secondary m-1" onClick={handleBack}>Quay lại</button>
                <button type="submit" className="btn btn-primary m-1">{item ? 'Update' : 'Tạo'} </button>
            </form>
        </div>
    </div>
    );
}

export default CreateItem;
