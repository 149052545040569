import axios from "axios";

const saveLocalStorage = async (nameStorage, link, setData, params = {}) =>{
    try {
        const response = await axios.get(process.env.REACT_APP_HOST + link, {
            params
        });
        let data = response.data;
        if(nameStorage === "item_noi_bat" || nameStorage === "item_giam_gia"){
            console.log(data.itemPageMain);
            setData(data.itemPageMain);
        }else
            setData(data);
        localStorage.setItem(nameStorage, JSON.stringify(data));
    } catch (error) {
        console.error("Error fetching collections:", error);
    }
}

const loadLocalStorage = (nameStorage) =>{
    const storedData = localStorage.getItem(nameStorage);
    // Kiểm tra nếu dữ liệu tồn tại và nếu có thì parse thành mảng
    return storedData ? JSON.parse(storedData) : null;
}

export { loadLocalStorage, saveLocalStorage };