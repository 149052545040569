import React, {useEffect, useRef, useState} from "react";
import logo from "../../assets/logo.png";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
import user from "../../assets/icons/icon_user.png";
import icon_cart from "../../assets/icons/icon_cart.png";
import Search from "./Search";
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';

const Header = () => {


  const ref_width= useRef(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navigate = useNavigate();
  const cartLength = useSelector((state) => state.cart.items.length)

  useEffect(()=>{
    if (ref_width.current) {
      const element = ref_width.current;
      const { width, height } = element.getBoundingClientRect();

      const widthAdjustment = width / 2;
      element.style.right = `-${widthAdjustment}px`;

      const heightAdjustment = height / 2;
      element.style.bottom = `-${heightAdjustment}px`;
    }
  }, [])

  const [currentLogo, setCurrentLogo] = useState(logo);

  useEffect(() => {
    // Hàm đổi logo
    const switchLogo = () => {
      setCurrentLogo((prevLogo) => {
        if (prevLogo === logo) return logo1;
        if (prevLogo === logo1) return logo2;
        return logo; // Quay lại logo ban đầu
      });
    };
  
    // Thiết lập interval để đổi logo mỗi 5 giây
    const intervalId = setInterval(switchLogo, 5000);
  
    // Dọn dẹp interval khi component unmount
    return () => clearInterval(intervalId);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleNav = () => {
    setIsNavVisible(prevState => !prevState); // Đảo ngược trạng thái
  };

  const handleClickUSer = ()=>{
    navigate('/login');
  }


  return (
    <header className="shadow fixed z-[1200] bg-white w-full">
      <div className="container-fluid p-0">

      <div className="grid md:grid-cols-7 md:gap-4 grid-cols-4">
        <div className="p-2 flex items-center justify-center">
          <a className="flex justify-center items-center " href="/">
            <img src={currentLogo} alt="logo" className="img-fluid  md:w-20 w-16  justify-center" />
          </a>
        </div>
        <div className=" p-2 md:col-span-3 hidden md:flex items-center grid-cols-3 justify-center gap-4 xl:gap-8 text-black">
            <div className="pt-2 pb-2 xl:ml-5 xl:mr-5 xl:text-xl">
                <a className="no-underline text-[#000000] hover:text-red-600  " href="/">
                     Home
                </a>
            </div>
            <div className=" pt-2 pb-2 xl:ml-5 xl:mr-5 xl:text-xl">
              <a className="no-underline text-[#000000] hover:text-red-600 " href="/collection">
                Collection
              </a>
            </div>
            <div className=" pb-2 pt-2 xl:ml-5 xl:mr-5 xl:text-xl">
              <a className="no-underline text-[#000000] hover:text-red-600 " href="/contact">
                 About Us  
              </a>
            </div>
        </div>
        <div className="p-4 col-span-2  flex justify-end items-center">
            <Search />
        </div>
        <div className=" flex justify-center items-center">
          <nav>
            <ul className="nav justify-content-end align-items-center">
              <li className="nav-item ml-1 mr-1 lg:mr-2 lg:ml-2">
                <img src={user} alt="Icon" className="cursor-pointer lg:w-8 lg:h-8 w-6 h-6 " onClick={handleClickUSer} />
              </li>
              <li className="nav-item position-relative ml-1 mr-1 lg:mr-2 lg:ml-2">
                <a href="/cart"><img src={icon_cart} alt="Icon" className="cursor-pointer lg:w-8 lg:h-8 w-6 h-6 " /></a>
                <p ref={ref_width}  className="absolute bg-red-500 bottom-0 right-0 text-white text-[10px]
                 rounded-[50%] pl-1 pr-1">{cartLength}</p>
              </li>
            </ul>
          </nav>
          <div className="ml-2 mr-2  md:hidden bg-gray-600">
            <button className="pl-1 pr-1" onClick={toggleNav}>  ☰ </button>
          </div>
        </div>

        </div>  
        
        <div className={`grid-cols-1 ${isNavVisible ? "fixed top-[60px] left-0 w-full" : "hidden"}`}>
          <div className="bg-gray-50 pt-2 pb-2">
              <a className="no-underline text-black hover:text-red-500 ml-2" href="/">
                    About Us
              </a>
          </div>
          <div className="bg-gray-50 pt-2 pb-2">
            <a className="no-underline text-black hover:text-red-500 ml-2" href="/collection">
              Collection
            </a>
          </div>
          <div className="bg-gray-50 pb-2 pt-2">
            <a className="no-underline text-black hover:text-red-500 ml-2" href="/contact">
              Contact
            </a>
          </div>

          </div>      
      </div>
    </header>
  );
};

export default Header;
