import React from "react";
import HighlightItem from "../../components/Admin/HighlightItem";

const HighLightItemAdmin = () =>{
    return (
        <>
            <HighlightItem />
        </>
    );
}

export default HighLightItemAdmin;