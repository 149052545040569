import React,  { forwardRef, useState }  from 'react';
import iconArrow from '../../assets/icons/icon_arrow.png';

const Filter = forwardRef(({ isOpenFilter, toggleSidebar, updateColor, color, updateSize, size, cate, updateCate }, ref) => {

  const [isOpenSize, setIsOpenSize] = useState(false);
  const handleOpenSize = () => {   
    setIsOpenSize(prev => !prev);
  };

  const [isOpenCate, setIsOpenCate] = useState(false);
  const handleOpenCate = () => {
    setIsOpenCate(prev => !prev);
  };

  const [isOpenColor, setIsOpencolor] = useState(false);
  const handleOpencolor = () => {   
    setIsOpencolor(prev => !prev);
  };

  const handleColorClick = (colorName) => {
    // Kiểm tra xem màu đã có trong chuỗi chưa
    if (color.includes(colorName)) {
      // Nếu có rồi, loại bỏ màu đó khỏi chuỗi
      const updatedColor = color
        .split(',') // Chia chuỗi thành mảng các màu
        .filter(c => c !== colorName) // Loại bỏ màu cần bỏ
        .join(','); // Nối lại các màu bằng dấu ","
      updateColor(updatedColor);
    } else {
      // Nếu chưa có, thêm màu đó vào chuỗi và nối bằng dấu ","
      updateColor(color ? `${color},${colorName}` : colorName); // Thêm màu mới vào
    }
  };

  const handleSizeClick = (sizeName) => {
    
    // Kiểm tra xem kích thước đã có trong mảng chưa
    if (size.includes(sizeName)) {
      // Nếu có rồi, loại bỏ kích thước đó khỏi mảng
      updateSize(size.filter(item => item !== sizeName));
    } else {
      // Nếu chưa có, thêm kích thước đó vào mảng
      updateSize([...size, sizeName]);
    }
  };

  const handleCateClick = (category) => {
    
    // Kiểm tra xem kích thước đã có trong mảng chưa
    if (cate.includes(category)) {
      // Nếu có rồi, loại bỏ kích thước đó khỏi mảng
      updateCate(cate.filter(cate => cate !== category));
    } else {
      // Nếu chưa có, thêm kích thước đó vào mảng
      updateCate([...cate, category]);
    }
  };

  return (
    <>
      <button className="fixed xl:top-[110px] sm:top-[90px] lg:top-[105px] md:top-[100px] top-[65px] bg-gray-500 text-white border-none pt-2 
      pb-2 pl-3 pr-3 z-[1001]"  onClick={toggleSidebar}>  ☰ </button>
      <div ref={ref} className={`fixed xl:top-[110px] lg:top-[105px] md:top-[90px] sm:top-[90px] top-[65px] left-0  h-[100%] bg-[#fff] z-[1000]
        text-black pt-2 pb-5 pl-5 pr-5 transition-transform duration-300 ease-in-out xl:w-[15%] lg:w-[20%] md:w-[25%] sm:w-[45%]
        transform sidebar-guest ${isOpenFilter ? '' : '-translate-x-full'}`}>
        <p className='mb-2 ml-7 xl:text-[16px]'>New Arrivals</p>
        <ul className='p-0 list-none'>
          <li className='justify-content-between d-flex align-items-center pt-2 pb-2 border-b border-black cursor-pointer'>
            <p className='text-black no-underline m-0'>Prize</p>
            <img src={iconArrow} alt='iconArrow' className='w-5 h-4' />
          </li>
          <li className='border-b border-black cursor-pointer pt-2 pb-2'>
            <div className='w-100 justify-content-between d-flex align-items-center' onClick={handleOpencolor}>
              <p className='text-black no-underline m-0'>Colors</p>
              <img src={iconArrow} alt='iconArrow' className='w-5 h-4' />
            </div>
            <div className={`w-100 flex flex-row justify-start w-full flex-wrap mb-2 mt-2 
              transition-transform duration-300 ease-in-out ${isOpenColor ? 'd-none' : '' }` }>
                <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]'
                 onClick={() => handleColorClick("Trắng")} > Trắng {color.includes("Trắng") && "✔"}
                </p>
                <p className='text-black no-underline cursor-pointe rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
                onClick={() => handleColorClick("Đen")} > Đen {color.includes("Đen") && "✔"}
                </p>
                <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
                onClick={() => handleColorClick("Cream")} > Cream {color.includes("Cream") && "✔"}
                </p>
            </div>
          </li>
          <li className='border-b border-black cursor-pointer pt-2 pb-2'>
            <div className='w-100 justify-content-between d-flex align-items-center' onClick={handleOpenCate}>
              <p className='text-black no-underline m-0'>Category</p>
              <img src={iconArrow} alt='iconArrow' className='w-5 h-4' />
            </div>
            <div className={`w-100 flex flex-row justify-start w-full flex-wrap mb-2 mt-2 
              transition-transform duration-300 ease-in-out ${isOpenCate ? 'd-none' : '' }` }>
                <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]'
                 onClick={() => handleCateClick("Áo Thun Oversize")} > Áo Thun Oversize {cate.includes("Áo Thun Oversize") && "✔"}
                </p>
                <p className='text-black no-underline cursor-pointe rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
                onClick={() => handleCateClick("Áo Sweater")} > Áo Sweater {cate.includes("Áo Sweater") && "✔"}
                </p>
            </div>
          </li>
          <li className='border-b border-black cursor-pointer pt-2 pb-2'>
            <div className='w-100 justify-content-between d-flex align-items-center' onClick={handleOpenSize}>
              <p className='m-0'>Size</p>
              <img src={iconArrow} alt='iconArrow' className='w-5 h-4' />
            </div>
            <div className={`w-100 flex flex-row justify-start w-full flex-wrap mb-2 mt-2 ${isOpenSize ? 'd-none' : '' }` }>
            <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
              onClick={() => handleSizeClick("S")}> S {size.includes("S") && "✔"}
            </p>
            <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
              onClick={() => handleSizeClick("M")}> M {size.includes("M") && "✔"}
            </p>
            <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
              onClick={() => handleSizeClick("L")}> L {size.includes("L") && "✔"}
            </p>
            <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
              onClick={() => handleSizeClick("XL")}> XL {size.includes("XL") && "✔"}
            </p>
            <p className='text-black no-underline cursor-pointer rounded border border-black m-1 pt-1 pb-1 pl-2 pr-2 hover:bg-[bisque]' 
              onClick={() => handleSizeClick("XXL")}> XXL {size.includes("XXL") && "✔"}
            </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
});

export default Filter;