import React from 'react';
import iconRow from "../../assets/icons/icon_row.png";
import iconClose from "../../assets/icons/icon_close.png";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  removeFromCart, updateCart } from '../../redux/cartSlice';


const ItemCart = () =>{

    const navigate = useNavigate(); 

    const cart = useSelector((state) => state.cart.items);
    const total = useSelector((state) => state.cart.totalPrice);
    const subtotal = useSelector((state) => state.cart.totalPrice);
    const dispatch = useDispatch();
    
    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    const handleCreaseQuantity = (index, quantity) =>{
        dispatch(updateCart({ index: index, quantity: quantity + 1 })); 
    }

    const handleReduceQuantity = (index, quantity) => {    
        if(quantity === 1)
            return;
        dispatch(updateCart({ index: index, quantity: quantity - 1 })); 
    };

    const handleRemoverCart = (index) =>{
        dispatch(removeFromCart({index}))
    }

    const handlePayment = async () =>{
        return navigate("/payment");
    }

    return (
        <div className="cart container">
            <div className="row">
            <p className="text-4xl pl-4 m-0">Cart</p>
                <div className="col-xl-8 col-md-12">
                    <ul className='list-none'>
                    {cart && cart.map((item, index) => (
                        <li key={index} className="item-cart-wrapper"> 
                            <div className="row border-b-2 pt-3 pb-3">
                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    <img src={item.fontImage} alt="item"  className="w-40 h-40"/>
                                </div>
                                <div className="col-6">
                                    <p className="text-xl text-red-500 mb-1">{item.name}</p>
                                    <p className="font-tiktok-regular text-sm mb-1">Asl T-shiert</p>
                                    <div className='mt-2'>
                                        <div className="text-base font-tiktok-regular pt-1 pb-1 pl-2 pr-2 
                                        inline-block rounded-md border-black border-0.5 h-8">{item.color}</div>
                                        <div className="text-base font-tiktok-regular pt-1 pb-1 pl-4 pr-4 
                                        inline-block rounded-md border-black border-0.5 ml-2 h-8">{item.size}</div>
                                    </div>
                                    <div className='flex items-center mt-4'>
                                        <p className='font-tiktok-regular text-sm m-0'>Quantity</p>
                                        <div className='flex items-center ml-4'>
                                            <div className='cursor-pointer' onClick={()=>{handleReduceQuantity(index, item.quantity)}}>
                                                <img src={iconRow} alt='iconRow' className='w-6 h-6 transform rotate-[90deg]'/>
                                            </div>
                                            <span className='text-2xl ml-3 mr-3 '>{item.quantity}</span>
                                            <div className='cursor-pointer' onClick={()=>{handleCreaseQuantity(index, item.quantity)}} >
                                                <img src={iconRow} alt='iconRow' className='w-6 h-6 transform rotate-[270deg]'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center position-relative">
                                    <p className="text-2xl absolute top-0 text-center">{convertMoney(item.total)}</p>
                                    <div className='cursor-pointer' onClick={()=>{handleRemoverCart(index)}}>
                                        <img src={iconClose} alt='icon-close' className='w-9 h-9 hover:w-10 hover:h-10'  />
                                    </div>
                                </div>
                            </div>
                        </li>
                    
                    ))}
                    </ul>
                </div>
                
                <div className="col-xl-4 col-md-12">
                    <p className="text-3xl pt-1 mb-1">Summary</p>
                    <ul className="p-0">
                        <li>
                            <div className="container-fluid">
                                <div className="row w-full m-0 d-flex justify-content-between">
                                    <div className="col-7 p-0">
                                        <p className="text-xl mb-1">Subtotal</p>
                                    </div>
                                    <div className="col-5 d-flex justify-content-end align-items-end">
                                        <p className="text-xl mb-1">{convertMoney(subtotal)}</p>
                                    </div>
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className="container-fluid">
                                <div className="row w-full m-0 d-flex justify-content-between">
                                    <div className="col-7 p-0">
                                        <p className="text-xl mb-1">Total</p>
                                    </div>
                                    <div className="col-5 d-flex justify-content-end align-items-end">
                                        <p className="text-xl mb-1">{convertMoney(total)}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className='d-flex justify-content-center'>
                        <button className="items-center w-3/4 pt-3 pb-3 font-tiktok-medium text-xl 
                         border-0.5 border-black rounded-3xl bg-[#000000] text-[#ffffff] shadow-md 
                         hover:bg-white hover:text-red-500" onClick={handlePayment}>Payment</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemCart;