import React from "react";

const FunctionAdmin = ({ refSlideBar, isOpenFilterAdmin, toggleSidebarAdmin }) =>{
    return(
        <>
        <button className="fixed bg-gray-500 top-[80px] cursor-pointer z-[1100] px-2 py-2" onClick={toggleSidebarAdmin}>  ☰ </button>
        <div ref={refSlideBar} className={`text-[0x000000] bg-[#db9e71] w-[15%] fixed top-[80px]
            transform  transition-transform duration-300 ease-in-out px-2 h-full z-[1000]
             ${isOpenFilterAdmin ? 'transform: translateX(0)' : 'translate-x-[-100%]'}`}>
            <h3 className="pl-6 pt-2">Dashboard</h3>
            <ul className="p-0">
                <li className="py-2 border-b-0.5 border-black">
                    <div className='w-100 flex justify-between items-center' >
                        <p className="m-0"><a href="/admin/collection" className="no-underline text-[#000000] hover:text-gray-500">Bộ sưu tập</a></p>
                    </div>
                </li>
               
                <li className="py-2 border-b-0.5 border-black">
                    <div className='w-100 flex justify-between items-center' >
                        <p className="m-0"><a href="/admin/item" className="no-underline text-[#000000] hover:text-gray-500">Sản phẩm</a></p>
                    </div>
                </li>
                    
                <li className="py-2 border-b-0.5 border-black">
                    <div className='w-100 flex justify-between items-center' >
                        <p className="m-0"><a href="/admin/highlightItem" className="no-underline text-[#000000] hover:text-gray-500">Sản phẩm nổi bật</a></p>
                    </div>
                </li>

                <li className="py-2 border-b-0.5 border-black">
                    <div className='w-100 flex justify-between items-center' >
                        <p className="m-0"><a href="/admin/order" className="no-underline text-[#000000] hover:text-gray-500">Đơn hàng</a></p>
                    </div>
                </li>

            </ul>
        </div>
        </>
    );
}

export default FunctionAdmin;