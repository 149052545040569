import React, {useState} from "react";
import logo from '../../assets/logo.png';
import iconUser from '../../assets/icons/icon_user.png';
import iconGoogle from '../../assets/icons/icon_google.png';
import iconApple from '../../assets/icons/icon_apple.png';
import iconFace from '../../assets/icons/icon_facebook.png';
import axios from "axios";

const Register =() =>{

    const [name, setName] = useState('');
    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [validName, setValidName] = useState("");
    const [validUsername, setValidUsername] = useState("");
    const [validEmail, setValidEmail] = useState("");
    const [validPhone, setValidPhone] = useState("");
    const [validPass, setValidPass] = useState("");
    const [validConfirmPass, setValidConfirmPass] = useState("");

    const resetValid = () =>{
        setValidName("");
        setValidUsername("");
        setValidEmail("");
        setValidPhone("");
        setValidPass("");
        setValidConfirmPass("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Ngăn chặn hành động mặc định của form (gửi dữ liệu và tải lại trang)
        // Xử lý dữ liệu form bằng JavaScript ở đâ
        resetValid();

        let isValid = false;

        if(name === ""){
            setValidName("Bạn chưa nhập tên");
            isValid = true;
        }
        if(username === ""){
            setValidUsername("Bạn chưa nhập tài khoản");
            isValid = true;
        }
        if(email === ""){
            setValidEmail("Bạn chưa nhập email");
            isValid = true;
        }
        if(phone === ""){
            setValidPhone("Bạn chưa nhập số điện thoại");
            isValid = true;
        }
        if(password === ""){
            setValidPass("Bạn chưa nhập mật khẩu");
            isValid = true;
        }
        if(confirmPassword === ""){
            setValidConfirmPass("Bạn chưa nhập xác nhận mật khẩu");
            isValid = true;
        }
        if(confirmPassword !== password){
            setValidConfirmPass("Mật khẩu xác nhận không chính xác");
            isValid = true;
        }

        if(isValid)
            return;

        const response = await axios.post(process.env.REACT_APP_HOST + '/register', 
        {
            name : name,
            username : username,
            password: password,
            email : email,
            phone: phone
        },
        {
            headers: {
                'Content-Type': 'application/json' // Đảm bảo kiểu nội dung là JSON
            }
        });
        console.log('Create successful:', response.data);
    }

    const handleInputNumric = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Only allow digits
            setPhone(value);
        }
    };

    return (
        <>
            <div className="container  d-flex justify-content-center align-items-center vh-100">
                <div className="w-[500px] bg-[#fde9d1] rounded-xl" >
                    <div className="pt-5 pb-5">
                        <div className=" d-flex justify-content-center align-items-center">
                            <img src={logo} alt="logo" className="w-36 h-24" />
                        </div>
                        <div className="icon-user d-flex justify-content-center align-items-center mb-2">
                            <img src={iconUser} alt="iconUser" />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex justify-content-center align-items-center p-2">
                                <div className="col-12 col-xxl-9">
                                    <div className="mb-4">
                                        <input type="text" className="form-control" placeholder="Name" onChange={(e) => setName(e.target.value)}/>
                                        <div className="form-text mx-1 text-red-500">{validName}</div>
                                    </div>
                                    <div className="mb-4">
                                        <input type="text" className="form-control" placeholder="Username" onChange={(e) => setUserName(e.target.value)}/>
                                        <div className="form-text mx-1 text-red-500">{validUsername}</div>
                                    </div>
                                    <div className="mb-4">
                                        <input type="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                                        <div className="form-text mx-1 text-red-500">{validEmail}</div>
                                    </div>
                                    <div className="mb-4">
                                        <input type="text" className="form-control" placeholder="Phone" value={phone}  onChange={handleInputNumric} />
                                        <div className="form-text mx-1 text-red-500">{validPhone}</div>
                                    </div>
                                    <div className="mb-4">
                                        <input type="password" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                                        <div className="form-text mx-1 text-red-500">{validPass}</div>
                                    </div>
                                    <div className="mb-4">
                                        <input type="password" className="form-control" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                                        <div className="form-text mx-1 text-red-500">{validConfirmPass}</div>
                                    </div>

                                    <button type="submit" className="bg-[#fde9d1] border-0.5 border-black w-100
                                   rounded py-1 hover:bg-[#fec68b] " >Register</button>
                                </div>
                            </div>
                        </form>
                        <div className="row flex justify-center items-center mt-3">
                            <div className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div>
                                    <div className="flex justify-center items-center bg-[#ffffff] px-3 py-2 cursor-pointer rounded-xl hover:bg-[#fec68b]">
                                        <img src={iconGoogle} alt="icon_google" className="w-8 h-8"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div>
                                    <div className="flex justify-center items-center bg-[#ffffff] px-3 py-2 cursor-pointer rounded-xl hover:bg-[#fec68b]">
                                        <img src={iconApple} alt="icon_google" className="w-8 h-8"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div>
                                    <div className="flex justify-center items-center bg-[#ffffff] px-3 py-2 cursor-pointer rounded-xl hover:bg-[#fec68b] ">
                                        <img src={iconFace} alt="icon_google" className="w-8 h-8"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        

    );
}

export default Register;