import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DialogSearchItem from '../components/Dialog/DialogSearchItem/DialogSearchItem';


// Biến lưu trữ trạng thái của Dialog và các hàm liên quan
let dialogData = {
    isOpen: false,
    typeHighlight: 0,
    onSuccess: () => {},
  };
  
let setDialogData;

// Hàm để hiển thị Dialog
export const openDialogSearchItem = (onSuccess, typeHighlight) => {
    setDialogData({
      isOpen: true,
      typeHighlight,
      onSuccess,
    });


};

// Hàm để đóng Dialog
export const closeDialogSearchItem = () => {
    setDialogData({
      ...dialogData,
      isOpen: false,
    });
};

const DialogSearchItemServie = () =>{
    const [localData, setLocalData] = useState(dialogData);
    setDialogData = setLocalData; // Gán setDialogData để cập nhật trạng thái
    if (!localData.isOpen) return null; // Không hiển thị khi isOpen = false

    return ReactDOM.createPortal(
        <DialogSearchItem onClose={localData.onConfirm != null ? localData.onConfirm : closeDialogSearchItem} 
        onSuccess = {localData.onSuccess} typeHighlight={localData.typeHighlight} />,
        document.body
    )
}

export default DialogSearchItemServie;