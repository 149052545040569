import React, {useEffect, useState, useCallback} from "react";
import axios from "axios";
import { openDialogWaitingAdmin, closeDialogWaitingAdmin } from "../../service/DialogWatingAdminService";
import Pagination from "../Guest/Pagination";

const ListOrder = ()=>{

    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [amountPage, setAmountPage] = useState(1);
    const [isViewReceipt, setIsViewReceipt] = useState(false);
    const [itemInOrder, setItemInOrder] = useState([]);
    const [totalReceipt, setTotalReceipt] = useState(0);

    const fetchOrder = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/receipts', {
                params: { page } // Truyền tham số page vào yêu cầu
            });

            // Tính tổng số trang
            const totalPages = Math.ceil(response.data.total / response.data.limit);
            // Cập nhật số trang và dữ liệu bộ sưu tập
            setAmountPage(totalPages);
            setOrders(response.data.receipts);
            console.log(response.data.receipts);
            closeDialogWaitingAdmin();
        } catch (error) {
            console.error('Lỗi khi lấy bộ sưu tập:', error);
            // Xử lý lỗi, chẳng hạn như hiển thị thông báo lỗi
        }
    }, [page]);

    useEffect(() => {
        openDialogWaitingAdmin();
        fetchOrder(); // Lấy dữ liệu bộ sưu tập khi component được tải
    }, [fetchOrder]);

    const onPageChange = (page)=>{
        setPage(page);
    }

    const fetchItemInOrder = async (id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/getItemInOrder/' + id);
            console.log(response);
            setItemInOrder(response.data);
            closeDialogWaitingAdmin();
        } catch (error) {
            console.log("Error fetching collections: ", error);
        }
    };

    const handleClickDetailOrder = (idReceipt, total) =>{
        setIsViewReceipt(true);
        openDialogWaitingAdmin();
        fetchItemInOrder(idReceipt);
        setTotalReceipt(total);
    }

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    const handleClickBack = () =>{
        setIsViewReceipt(false);
    }

    return (
        <>
        {isViewReceipt ? (
            <>
                <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6">
                        {/* Phần search */}
                    </div>
                    <div className="col-xxl-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tên</th>
                                    <th scope="col">Số lượng</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Màu sắc</th>
                                    <th scope="col">Giá</th>
                                </tr>
                            </thead>
                            <tbody>
                            {itemInOrder && itemInOrder.map((item) => (
                                <tr key={item.id}>
                                    <th scope="row" className="align-middle">{item.id}</th>
                                    <th scope="row" className="align-middle">{item.name}</th>
                                    <th scope="row" className="align-middle">{item.quantity}</th>
                                    <th scope="row" className="align-middle">{item.size}</th>
                                    <th scope="row" className="align-middle">{item.color}</th>
                                    <th scope="row" className="align-middle">{convertMoney(item.price)}</th>
                                </tr>
                            ))}
                            <tr class="thead-light">
                                <th scope="row" colSpan="5">Tổng</th>
                                <th scope="row">{convertMoney(totalReceipt)}</th>
                            </tr>
                            </tbody>
                        </table>
                        <button type="button" class="btn btn-secondary" onClick={handleClickBack}>Back</button>
                    </div>
                </div>
            </div>
            </>
        ) : (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6">
                        {/* Phần search */}
                    </div>
                    <div className="col-xxl-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tên</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Số điện thoại</th>
                                    <th scope="col">Địa chỉ</th>
                                    <th scope="col">Xem thêm</th>
                                </tr>
                            </thead>
                            <tbody>
                            {orders && orders.map((order) => (
                                <tr key={order.id}>
                                    <th scope="row" className="align-middle">{order.id}</th>
                                    <td className="align-middle">{order.name}</td>
                                    <td className="align-middle">{order.email}</td>
                                    <td className="align-middle">{order.phone}</td>
                                    <td className="align-middle">{order.address}</td>
                                    <td className="align-middle">
                                        <button type="button" className="btn btn-warning m-1" 
                                        onClick={(e) => handleClickDetailOrder(order.id, order.total)}>Xem thêm</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination currentPage={page} amountPage={amountPage} onPageChange={onPageChange} />
                    </div>
                </div>
            </div>
        </div>
        )}
        
    </>
    );
   
}

export default ListOrder;