import React, {useState, useEffect } from 'react';
import rectHuge from '../../assets/rect_huge.png';
import axios from 'axios';
import { openDialogGuest } from '../../service/DialogGuestService';
import iconRow from '../../assets/icons/icon_row.png';
import { addToCart } from '../../redux/cartSlice';
import { useDispatch } from 'react-redux';

const DetailsProduct = ({id}) =>{

    const [item, setItem] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    
    const fetchItem = async (id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/items/' + id);
            setItem(response.data);
        } catch (error) {
            console.error("Error fetching items: ", error);
        }
    };

    useEffect(() =>{
        if (id) {
            fetchItem(id);
        }
    }, [id])

    const [urls, setUrls] = useState([]);
    const [listSizes, setListSizes] = useState([]);
    const [listColors, setListColors] = useState([]);
    const [imgMain, setImgMain] = useState(null);

    useEffect(() => {
        if (item && item.listImage) {
            const arrayImage = item.listImage.split(',');
            const updatedImageList = arrayImage.map(image => `${process.env.REACT_APP_UPLOAD}${image}`);
            setImgMain(updatedImageList[0]);
            setUrls(updatedImageList);
            const arraySizes = item.sizes.split(',');
            setListSizes(arraySizes);
            const arrayColors = item.colors.split(',');
            setListColors(arrayColors);
        }
    }, [item]);

    const handleClickImg = (url) =>{
        setImgMain(url);
    }

    const [size, setSize] = useState(null);
    const hadleUpdateSize = (size) =>{
        setSize(size);
    }

    const [color, setColor] = useState(null);
    const handleUpdatecolor = (color) =>{
        setColor(color);
    }

    const handleAddToCart = () =>{
        if(size === null){
            openDialogGuest("Thông báo", "Bạn chưa chọn size", null);
            return;
        }
        if(color === null){
            openDialogGuest("Thông báo", "Bạn chưa chọn màu ", null);
            return;
        }
        const product = {
            itemId: id,
            name: item.name,
            price: item.price,
            size: size,
            color: color,
            quantity : quantity,
            total: quantity * item.price,
            fontImage : process.env.REACT_APP_UPLOAD + item.fontImage
        };

        dispatch(addToCart(product));
        openDialogGuest("Thông báo", "Thêm vào giỏ hàng thành công", null);
    }

    const handleIcreaseQuantity = () =>{
        setQuantity(prevQuantity => prevQuantity + 1);
    }

    const handleReduceQuantity = () =>{
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));
    }

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }

    return (
        <div className='coitainer-fluid'>
            <div className='row bg-[#fff] pt-5 pb-5 shadow rounded'>
                {item ? (
                    <>
                     <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12'>
                        <ul className='list-none no-underline'>
                        {urls.map((url, index) => (
                            <li key={index} className='mb-4 inline-flex mr-5 md:flex md:justify-center' onClick={() => handleClickImg(url)}>
                                <img src={url} alt={`img-${index}`} 
                                className='w-24 h-24 cursor-pointer hover:border-0.5 hover:border-black 
                                bg-[#fff] border-0.5 border-[#dee2e6] rounded-md max-w-full' />
                            </li>
                        ))}
                        </ul>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 flex justify-center items-center'>
                        <img src={imgMain ? imgMain : rectHuge} alt='img' 
                        className='w-full block h-auto p-1 bg-[#fff] border-0.5 border-[#dee2e6] rounded-md max-w-full'/>
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <p className='text-red-500 text-2xl mb-1'>{item.name}</p>
                        <p className='font-tiktok-medium text-lg text-gray-400 mt-1 mb-1'>{item.Collection.name}</p>
                        <p className='font-tiktok-medium text-3xl mt-1 mb-1'>{convertMoney(item.price)}</p>
                        <div className='list-img'>
                            <ul className='d-flex justify-content-start p-0 m-0'>
                            {urls.map((url, index) => (
                                <li key={index} className='m-1'>
                                    <img src={url} alt={`img-${index}`} className='w-20 h-20 p-1 bg-[#fff] border-0.5 border-[#dee2e6] rounded-md max-w-full'/>
                                </li>

                            ))}
                            </ul>
                        </div>

                    <p className='text-xl mb-1'>Size</p>
                    <div className='list-size'>
                        <ul className='d-flex justify-content-start p-0 m-0'>
                        {listSizes.map((size0, index) => (
                            <li key={index}>
                                <button className={`pt-1 pb-1 pl-4 pr-4 m-1 
                                border-0.5 border-black rounded cursor-pointer hover:bg-[bisque] ${size === size0 ? ' bg-[bisque] ' : ''}`} 
                                onClick={() => hadleUpdateSize(size0)}>{size0}</button>
                            </li>

                        ))}
                        </ul>
                    </div>

                    <p className='text-xl mb-1'>Colors</p>
                    <div className='list-size'>
                        <ul className='d-flex justify-content-start p-0 m-0'>
                        {listColors.map((color0, index) => (
                            <li key={index}>
                                <button className={`pt-1 pb-1 pl-4 pr-4 m-1 
                                border-0.5 border-black rounded cursor-pointer hover:bg-[bisque] ${color === color0 ? 'bg-[bisque]' : ''}`} 
                                onClick={() => handleUpdatecolor(color0)}>{color0}</button>
                            </li>

                        ))}
                        </ul>
                    </div>

                    <div className='flex items-center pb-3 pt-3'>
                        <p className='text-xl mb-1'>Quantity</p>
                        <div className='flex items-center ml-3'>
                            <div className='cursor-pointer' onClick={handleReduceQuantity}>
                                <img src={iconRow} alt='iconRow' className='w-8 h-8 rotate-[90deg]'/>
                            </div>
                            <span className='text-2xl ml-3 mr-3'>{quantity}</span>
                            <div className='cursor-pointer' onClick={handleIcreaseQuantity}>
                                <img src={iconRow} alt='iconRow' className='w-8 h-8 rotate-[270deg]'/>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column justify-content-center center-img-main'>
                        <button className='items-center xl:w-1/2 sm:w-4/5 md:w-4/5 lg:w-3/4 pt-2 pb-2 font-tiktok-medium 
                        text-xl border-0.5 border-black rounded-3xl bg-[#000000] text-[#ffffff] shadow
                        hover:bg-white  hover:text-red-500' onClick={handleAddToCart}>Add to bag</button>
                        <button className='items-center xl:w-1/2 sm:w-4/5 md:w-4/5 lg:w-3/4 pt-2 pb-2 mt-3 font-tiktok-medium 
                        text-xl border-0.5 border-black rounded-3xl bg-white text-black shadow
                         hover:text-red-500'>Favorite</button>
                    </div>
                </div>
                </>
                ) : (
                    <p>Loading...</p>
                )}
               

            </div>

            <div className='bg-white shadow-lg mt-4 '>
                <div className='ml-2 mr-2' >
                    <h2 className='mt-2'>MÔ TẢ SẢN PHẨM</h2>
                </div>
                <div className='ml-2 mr-2'>
                {item && item.description ? (
                    <p className='font-tiktok-medium' dangerouslySetInnerHTML={{ __html: item.description.replace(/\r\n|\r/g, "<br />") }}></p>
                ) : null}
                     
                </div>
            </div>
        </div>
    );
}

export default DetailsProduct;