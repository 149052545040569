import React from 'react';
import { Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from '../pages/Admin/AdminDashboard';
import CollectionAdmin from '../pages/Admin/CollectionAdmin';
import ItemAdmin from '../pages/Admin/ItemAdmin';
import DialogService from '../service/DialogService';
import DialogWaitingAdminServie from '../service/DialogWatingAdminService';
import DialogYesNoAdminServie from '../service/DialogYesNoAdminService';
import HighLightItemAdmin from '../pages/Admin/HighlightItemAdmin';
import DialogSearchItemServie from '../service/DialogSearchItemService';
import ListOrder from '../components/Admin/ListOrder';
import { useSelector } from 'react-redux';

const AdminRoutes = () => {

  const user = useSelector((state) => state.auth.user);



  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <DialogService />
      <DialogWaitingAdminServie />
      <DialogYesNoAdminServie />
      <DialogSearchItemServie />
      
      <Routes>
      {/* Define admin routes here */}
        <Route path="/" element={<AdminDashboard />}>
          <Route path="collection" element={<CollectionAdmin />} />
          <Route path="item" element={<ItemAdmin />} />
          <Route path="highlightItem" element={<HighLightItemAdmin />} />
          <Route path="order" element={<ListOrder />} />
        </Route>
    </Routes>
    </>

  );
};

export default AdminRoutes;
