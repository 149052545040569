import React from 'react';

const Pagination = ({currentPage, amountPage, onPageChange}) =>{
    const pages = Array.from({ length: amountPage }, (_, index) => index + 1);

    const handlePageChange = (page) => {
        if (page > 0 && page <= amountPage) {
            onPageChange(page);
        }
    };
    return(
        <>
         <div>
            <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                {/* Previous Page Button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                    type="button"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    aria-label="Previous"
                    disabled={currentPage === 1}
                >
                    <span aria-hidden="true">&laquo;</span>
                </button>
                </li>

                {/* Page Number Buttons */}
                {pages.map(page => (
                    <li
                        key={page}
                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                    >
                        <button
                            type="button"
                            className="page-link"
                            onClick={() => onPageChange(page)}
                        >
                            {page}
                        </button>
                    </li>
                ))}

                {/* Next Page Button */}
                <li className={`page-item ${currentPage === amountPage ? 'disabled' : ''}`}>
                <button
                    type="button"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    aria-label="Next"
                    disabled={currentPage === amountPage}
                >
                    <span aria-hidden="true">&raquo;</span>
                </button>
                </li>
            </ul>
            </nav>

        </div>
        </>
    );
};

export default Pagination;