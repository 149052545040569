import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import CreateItem from "./CreateItem";
import Pagination from "../Guest/Pagination";
import { openDialogWaitingAdmin, closeDialogWaitingAdmin } from "../../service/DialogWatingAdminService";
import { openDialogYesNoAdmin } from "../../service/DialogYesNoAdminService";

const ItemManage = () =>{

    const [isCreateItem, setIsCreateItem] = useState(false);

    const handleAddItem = (e) => {
        e.preventDefault();
        setItem(null);
        setIsCreateItem(prev => !prev);
    };

    const [listItems, setListItems] = useState([]);
    const [page, setPage] = useState(1); // Quản lý trạng thái của trang hiện tại
    const [amountPage, setAmountPage] = useState(1);

    const fetchItems = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/getListItems', {
                params: { page } // Truyền tham số page vào yêu cầu
            });

            // Tính tổng số trang
            const totalPages = Math.ceil(response.data.total / response.data.limit);
            // Cập nhật số trang và dữ liệu bộ sưu tập
            setAmountPage(totalPages);
            setListItems(response.data.items);
            closeDialogWaitingAdmin();
        } catch (error) {
            console.error('Lỗi khi lấy bộ sưu tập:', error);
            // Xử lý lỗi, chẳng hạn như hiển thị thông báo lỗi
        }
    }, [page]);

    useEffect(() => {
        openDialogWaitingAdmin();
        fetchItems(); // Lấy dữ liệu bộ sưu tập khi component được tải
    }, [fetchItems]);

    const handleBack = (e) => {
        e.preventDefault();
        setIsCreateItem(prev => !prev);
        setItem(null);
        fetchItems();
    };

    const onPageChange = (page)=>{
        setPage(page);
    }

    const [item, setItem] = useState();

    const fetchItem = async (id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_HOST + '/items/' + id);
            setItem(response.data);
        } catch (error) {
            console.log("Error fetching items: ", error);
        }
    };

    const handleUpdateItem = async (id) => {
        openDialogWaitingAdmin();
        await fetchItem(id);
        setIsCreateItem(prev => !prev);
        closeDialogWaitingAdmin();
    };

    const onYes = async (id)=>{
        openDialogWaitingAdmin();
        try{
            const response = await axios.delete(process.env.REACT_APP_HOST + '/items/' + id);
            console.log(response);
            if(response.data.message === "Delete sucesss!!!"){
                await fetchItems();
            }
        }catch(error){
            console.log("Error fetching items: ", error);
        }
    }

    const handleDeleteItem = async (id) =>{
        openDialogYesNoAdmin("Bạn muốn xóa sản phẩm ?", ()=> onYes(id));
    }

    const convertMoney = (money)=>{
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        });
        return formatter.format(money);
    }


    return (
        <>
            {isCreateItem ? (
                <CreateItem item={item} handleBack={handleBack} />
        ) : (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6">
                        <button className="px-2 py-1 bg-[#db9e71] text-[#ffffff] rounded 
                        cursor-pointer hover:bg-[#b57849]" onClick={handleAddItem}>Thêm sản phẩm</button>
                    </div>
                    <div className="col-xxl-6">
                        {/* Phần search */}
                    </div>
                    <div className="col-xxl-12">
                        <table className="table">
                            <thead className="align-middle">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Sản phẩm</th>
                                    <th scope="col">Giá</th>
                                    <th scope="col">Mặt trước</th>
                                    <th scope="col">Mặt sau</th>
                                    <th scope="col">Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                            {listItems && listItems.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row" className="align-middle">{item.id}</th>
                                    <td className="align-middle">{item.name}</td>
                                    <td className="align-middle">{convertMoney(item.price)}</td>
                                    <td >
                                        <img src={process.env.REACT_APP_UPLOAD + item.fontImage} alt="fontImage" className="w-32 h-24" loading="lazy" />
                                    </td>
                                    <td >
                                        <img src={process.env.REACT_APP_UPLOAD + item.backImage} alt="backImage" className="w-32 h-24" loading="lazy"/>
                                    </td>
                                    <td className="align-middle">
                                        <button type="button" className="btn btn-warning m-1" onClick={() => handleUpdateItem(item.id)} >Sửa</button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => handleDeleteItem(item.id)}>Xóa</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Pagination currentPage={page} amountPage={amountPage} onPageChange={onPageChange} />
                    </div>
                </div>
            </div>
        )}
        </>
    );
}

export default ItemManage;