import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: JSON.parse(localStorage.getItem('cartItems')) || [], // Danh sách sản phẩm trong giỏ hàng
    totalPrice: JSON.parse(localStorage.getItem('totalPrice')) || 0, // Tổng giá tiền
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const item = action.payload;
            const existingItem = state.items.find((i) => i.itemId === item.itemId);
            if (existingItem && existingItem.size !== item.size && existingItem.color !== item.color) {
                existingItem.quantity += item.quantity || 1; // Nếu `item.quantity` không có, mặc định là 1
                existingItem.total = item.price * (existingItem.quantity || 1);
            } 
            else 
            {
                state.items.push({
                    ...item,
                    totalPrice: item.price * (item.quantity || 1),
                });
                state.totalPrice += item.total;
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
            localStorage.setItem('totalPrice', state.totalPrice);
        },
        updateCart: (state, action) => {
            const { index, quantity } = action.payload; // action.payload là đối tượng { itemId, quantity }
            const item = state.items[index];
            if (item) {
                const priceDifference = (quantity - item.quantity) * item.price;
                item.quantity = quantity; // Cập nhật số lượng
                item.total = item.price * quantity; // Cập nhật tổng giá
                state.totalQuantity += quantity - item.quantity; // Cập nhật tổng số lượng giỏ hàng
                state.totalPrice += priceDifference; // Cập nhật tổng giá giỏ hàng
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
            localStorage.setItem('totalPrice', state.totalPrice);
        },
        removeFromCart: (state, action) => {
            const index = action.payload.index;
            const item = state.items[index];
            if (item) {
                state.totalPrice -= item.total; // Giảm tổng giá tiền
                // Loại bỏ item tại chỉ số index
                state.items = state.items.filter((_, i) => i !== index);
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
            localStorage.setItem('totalPrice', state.totalPrice);
        },
        clearCart: (state) => {
            state.items = [];
            state.totalQuantity = 0;
            state.totalPrice = 0;
            localStorage.removeItem('cartItems');
            localStorage.removeItem('totalPrice');
        },
    },
});

export const { addToCart, removeFromCart, clearCart, updateCart } = cartSlice.actions;
export default cartSlice.reducer;
