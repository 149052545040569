import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token : localStorage.getItem('token') || null,
    user: localStorage.getItem('user') || null,
    isLogin: false
};


const userSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginAuth: (state, action) => {
            const {token , user} = action.payload;
            state.token = token;
            state.user = user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout: (state, action) => {
            state.token = null;
            localStorage.removeItem('token');
            state.user = null;
            localStorage.removeItem('user');
        }
    },
});

export const { loginAuth, logout, checkToken } = userSlice.actions;
export default userSlice.reducer;